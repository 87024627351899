<template>
  <div class="maintenance-modal">
    <div class="content">
      <h4 class="title">Under Maintenance</h4>
      <p class="text">
        Our website is currently undergoing maintenance.
        We will logout you for now.
      </p>
      <b-button
        class="pl-4 pr-4"
        variant="primary"
        @click="logout"
        size="sm">
        Close
      </b-button>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';

  export default {
    methods : {
      logout() {
        this.$router.replace('/logout');
        this.$emit('close');
      },

      autoLogout : _.debounce(function() {
        if (this.$store.getters['auth/isLoggedIn'] &&
          this.$store.getters['user/user'].user_type !== 0) {
          this.$router.replace('/logout');
          this.$emit('close');
        }
      }, 5000),
    },
    mounted() {
      this.autoLogout();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .maintenance-modal {
    padding: 1em;
    background-image: $slide-3;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .content {
    padding: 2rem 1rem;
    background-color: rgba(255,255,255,0.75);
    text-align: center;
  }

  .title {
    color: #fff;
    font-family: "WickedMouse";
    font-size: 1.75em !important;
    -webkit-text-stroke: 1px $primary-color !important;
  }

  .text {
    color: $primary-color;
    font-family: "ArialRoundedMTBold";
    font-size: 1.25em !important;
  }
</style>
<template>
  <div>
    <modals-container />
  </div>
</template>

<script>
  import MaintenanceModal from '@/components/common/MaintenanceModal';

  export default {
    data() {
      return {
        updatesInstance : 'updatesInst',
        currPath        : this.$route.path,
      }
    },
    methods : {

      /**
       * Updates Listener
       */
      async updatesListener() {
        const pb = this.$pnGetInstance(this.updatesInstance);
        const that = this;

        pb.addListener({
          message : function(m) {
            const module = m.message.ann.module;
            const name = m.message.ann.name;
            const value = m.message.ann.value;

            if (module === 'web-settings') {
              if (name === 'maintenance'){
                that.$store.dispatch('web/setMaintenance', value);
                if (that.$store.getters['auth/isLoggedIn'] &&
                  that.$store.getters['user/user'].user_type !== 0)
                  that.showMaintenanceModal();
                else if (!that.currPath.includes('/admin'))
                  location.reload();
              }

              that.$store.dispatch('web/getSettings');
            }
          },
        })
      },

      /**
       * Show Maintenance Modal
       */
      showMaintenanceModal() {
        this.$modal.show(MaintenanceModal, {}, {
          height       : 'auto',
          adaptive     : true,
          classes      : 'maintenance-modal',
          clickToClose : false,
        });
      },
    },
    created() {
      this.updatesListener();
    },
    mounted() {
      const channel = process.env.VUE_APP_ENVIRONMENT + '-updates';
      this.$pnSubscribe({
        channels : [channel],
      }, this.updatesInstance);

      if (localStorage.maintenance && localStorage.maintenance === 'true') {
        if (this.$store.getters['auth/isLoggedIn'] &&
          this.$store.getters['user/user'].user_type !== 0)
          this.showMaintenanceModal();
      }
    },
  }
</script>